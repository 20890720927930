html {
    height: 100%;
    min-width: 1000px;
    overflow-x: auto;
}

body {
    height: 100%;
    margin: 0;
    font-family: "Porsche Next", "Arial Narrow", Arial, sans-serif;
}


/* Custom Table */

.mat-mdc-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
}

.mat-mdc-row,
.mat-mdc-header-row {
    display: table-row;
}

.mat-mdc-cell,
.mat-mdc-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 10px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
}

mat-header-cell:first-of-type {
    padding-left: 24px !important;
}

.mat-mdc-header-cell {
    overflow: unset !important;
    padding: 0px 10px;
}


/* Custom menu */

.mat-mdc-menu-panel {
    min-height: unset !important;
}

.mat-drawer-inner-container {
    overflow: unset !important;
}

.mat-drawer-content {
    overflow-x: unset !important;
    background-color: #ffffff !important;
}

.mainContainerOverviewBody {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

mat-drawer-content[style^="margin-right:"]>div.mainContainerOverviewBody {
    margin-right: 0px !important;
}

.mainContainerOverviewHeader {
    display: inline-flex;
    width: 100%;
    position: relative;
    height: 80px;
}

.mainContainerOverviewHeader>p-button {
    float: right;
    position: absolute;
    right: 50px;
    bottom: 0px;
}

.mainContainerOverviewSearch {
    width: 500px;
}

.mainContainerOverviewSearch * {
    width: 100%;
    padding-right: 3px;
}

.mainContainerOverviewTableContainer {
    position: absolute;
    bottom: 80px;
    top: 80px;
    margin-top: 5px;
    right: 0px;
    left: 0px;
}

.mainContainerOverviewFooter {
    display: inline-flex;
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    place-content: center;
}

.mainContainerOverviewFooter>div:nth-child(1) {
    padding-top: 10px;
}

.mainContainerOverviewFooter>div:nth-child(2) {
    width: 200px;
    right: 0px;
    position: absolute;
    top: 10px;
}